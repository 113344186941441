import React from 'react'

import ArrowToRightIcon from '../../../icons/ArrowToRightIcon'

const SocialMedia: React.FC = () => {
  // NOTE: We are skipping this scocial media section until we integrated Instagram
  if (true) {
    return null
  }

  return (
    <section className="w-full section-spacing">
      <div className="flex flex-wrap w-full max-w-screen-xl 2xl:max-w-content-xl fullhd+:max-w-fullhd mx-auto px-4">
        <h2 className="section-headline">Folge uns auf Instagram</h2>

        <div className="grid grid-cols-1 md:grid-cols-3 fullhd+:grid-cols-4 gap-6 fullhd+:gap-10 flex-wrap w-full mt-8 md:mt-10">
          Social media cooming soon
        </div>

        <div className="w-full">
          <button
            type="button"
            className="flex bg-white text-black border-2 border-black mt-8 md:mt-0 px-5 pt-4 pb-3 font-bold text-sm fullhd:text-tiny uppercase tracking-wide hover:bg-gray-800 hover:text-white items-center md:mx-auto">
            <div className="inline-block mr-4 -mt-0.5">
              <ArrowToRightIcon />
            </div>

            <span className="-mt-0.5 inline-block">Discover & Educate</span>
          </button>
        </div>
      </div>
    </section>
  )
}

export default SocialMedia
