import React from 'react'

import { Image } from 'react-datocms'

import ArrowToRightIcon from '../../../icons/ArrowToRightIcon'
import ScrollableLink from '../../../components/shared/ScrollableLink'
import { IDatoCMSStageSection } from '../interfaces'

interface IStage {
  stageSection: IDatoCMSStageSection
}

const Stage: React.FC<IStage> = ({ stageSection }) => {
  const callToAction = stageSection.callToAction[0]
  const leftImage = stageSection.images[0]
  const rightImage = stageSection.images[1]

  return (
    <section className="w-full bg-gray-100">
      <div className="flex w-full max-w-fullhd-off fullhd+:max-w-wqhd mx-auto overflow-hidden">
        <div className="flex relative w-full lg:w-7/12 xl:w-8/12 h-75vh md:h-85vh lg:h-70vh xl:h-70vh 2xl:h-80vh fullhd:h-70vh overflow-hidden items-center justify-center">
          <Image
            className="object-cover h-inherit fullhd+:rounded-tr-xl"
            data={leftImage.responsiveImage}
            layout="fill"
            lazyLoad={false}
            objectFit="cover"
            objectPosition={`${leftImage.focalPoint.x * 100}% ${leftImage.focalPoint.y * 100}%`}
          />

          {/* TODO: Remove */}
          {/* <img */}
          {/*  src="/images/stage-left-test.jpg" */}
          {/*  className="object-cover h-inherit fullhd+:rounded-tr-xl" */}
          {/*  alt="stage left" */}
          {/*  width="100%" */}
          {/*  height="100%" */}
          {/* /> */}

          <div className="items-end absolute z-10 left-0 bottom-0 pr-16 pt-32 lg:pr-4 pb-10 lg:pb-8 xl:pb-16 2xl:pb-24 pl-4 lg:pl-8 xl:pl-16 2xl:pl-24 w-full bg-gradient-to-t from-gray-900-45">
            <h1 className="max-w-lg leading-headline font-extrabold text-4xl md:text-5xl lg:text-6xl xl:text-stage-variable 2xl:text-2xxl text-white w-full mb-8">
              {stageSection.headline}
            </h1>

            <ScrollableLink anchor="products">
              <button
                type="button"
                className="flex bg-white px-5 pt-4 pb-3 font-bold text-sm fullhd:text-tiny uppercase tracking-wide hover:bg-black hover:text-white items-center">
                <div className="inline-block mr-2 -mt-0.5">
                  <ArrowToRightIcon />
                </div>

                <span className="-mt-0.5 inline-block">{callToAction.buttonText}</span>
              </button>
            </ScrollableLink>
          </div>
        </div>

        <div className="relative lg:flex hidden w-5/12 xl:w-4/12 -mt-8 h-75vh md:h-85vh lg:h-70vh xl:h-70vh 2xl:h-80vh fullhd:h-70vh overflow-hidden items-center justify-center">
          <Image
            className="object-cover h-inherit fullhd+:rounded-bl-xl"
            data={rightImage.responsiveImage}
            layout="fill"
            lazyLoad={false}
            objectFit="cover"
            objectPosition={`${rightImage.focalPoint.x * 100}% ${rightImage.focalPoint.y * 100}%`}
          />

          {/* TODO: Remove */}
          {/* <img */}
          {/*  src="/images/stage-right-test.jpg" */}
          {/*  className="object-cover h-inherit fullhd+:rounded-bl-xl" */}
          {/*  alt="stage left" */}
          {/*  width="100%" */}
          {/*  height="100%" */}
          {/* /> */}
        </div>
      </div>
    </section>
  )
}

export default Stage
