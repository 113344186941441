import React, { useContext, useEffect } from 'react'

import { GetStaticProps } from 'next'

import PageDataService from '@lib/pageData/pageDataService'
import ContentSections from '../modules/homepage/components/ContentSections'
import Intro from '../modules/homepage/components/Intro'
import Layout from '../modules/layout/Layout'
import ShopContext from '../context/ShopContext'
import SocialMedia from '../modules/homepage/components/SocialMedia'
import Stage from '../modules/homepage/components/Stage'
import { IDatoCMSHomepage } from '../modules/homepage/interfaces'
import { IMenuItem } from '../modules/layout/header/navigation/menu.model'

import { IProductsBySlug } from '../modules/products/interfaces/productInterfaces'

interface IStartpageProps {
  // eslint-disable-next-line react/require-default-props
  homepage: IDatoCMSHomepage
  productsBySlug: IProductsBySlug
  topMenu: IMenuItem[]
}

const HomePage: React.FC<IStartpageProps> = ({ homepage, productsBySlug, topMenu }) => {
  const { setProductsBySlug } = useContext(ShopContext)

  useEffect(() => {
    setProductsBySlug(productsBySlug)
  }, [productsBySlug])

  return (
    <Layout
      description={homepage.seoTags.description}
      metaImage={homepage.seoTags.image.url}
      title={homepage.seoTags.title}
      topMenu={topMenu}>
      <Stage stageSection={homepage.stageSection[0]} />
      <Intro introSection={homepage.introSection[0]} />
      <ContentSections contentSections={homepage.contentSections} productsBySlug={productsBySlug} />
      <SocialMedia />
    </Layout>
  )
}

export const getStaticProps: GetStaticProps = async (context) => {
  const pageDataService = new PageDataService(context)

  const { homepage, topMenu, productsBySlug } = await pageDataService.getHomePageData()

  return {
    props: {
      topMenu,
      homepage,
      productsBySlug
    },
    ...pageDataService.getSettings()
  }
}

export default HomePage
