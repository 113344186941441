import React from 'react'

const DividerWithIcon: React.FC = () => (
  <div className="section-content-width relative">
    <img
      src="/images/logo/yoself-icon-lightgray.svg"
      alt="y"
      className="absolute mx-auto top-0 w-auto h-8 md:h-12 -mt-4 md:-mt-6 pb-0.5 right-0 left-0"
    />
    <hr className="border-1 border-gray-300" />
  </div>
)

export default DividerWithIcon
