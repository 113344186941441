import React from 'react'

import getContentSectionList from '@modules/contentSections/lib/getContentSectionList'
import { IProductsBySlug } from '@modules/products/interfaces/productInterfaces'
import DividerWithIcon from '@modules/homepage/components/ContentSections/DividerWithIcon'
import Divider from '@modules/homepage/components/ContentSections/Divider'
import { IDatoCMSContentSection } from '@modules/common/interfaces/datoCMSInterfaces'

interface IContentSections {
  contentSections: IDatoCMSContentSection[]
  productsBySlug: IProductsBySlug
}

const ContentSections: React.FC<IContentSections> = ({ contentSections, productsBySlug }) => {
  const renderedContentSections = getContentSectionList(contentSections, productsBySlug)

  return (
    <>
      {renderedContentSections.map((contentSection, index) => {
        const key = index + 1

        return (
          <React.Fragment key={key}>
            {contentSection}
            {index === 0 ? <DividerWithIcon /> : <Divider />}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default ContentSections
