import React from 'react'

import Image from 'next/image'

import { IDatoCMSIntroSection } from '../interfaces'

interface IIntro {
  introSection: IDatoCMSIntroSection
}

const Intro: React.FC<IIntro> = ({ introSection }) => {
  const leftImage = introSection.images[0]
  const rightImage = introSection.images[1]

  return (
    <section className="w-full bg-gray-100 mb-4 md:mb-16 xl:mb-24 lg:px-4">
      <div className="flex flex-wrap w-full max-w-content-xl mx-auto pt-16 md:pt-24 2xl:pt-32 pl-4 md:px-4 pb-16 md:pb-0">
        <div className="relative hidden md:flex flex-wrap w-full md:w-1/2 mb-8 md:-mb-12 items-center pr-24 md:pl-16 md:pr-0">
          <Image
            objectFit="cover"
            alt={leftImage.alt}
            quality="60"
            width="600"
            height="750"
            src={leftImage.url}
            className="object-cover -ml-4 md:ml-0 h-full min-w-img max-w-500px xl:max-w-570px rounded-br-xl md:rounded-tl-xl"
            objectPosition={`${leftImage.focalPoint.x * 100}% ${leftImage.focalPoint.y * 100}%`}
          />
        </div>
        <div className="relative w-full flex md:hidden items-center">
          <div className="relative flex md:hidden ml-auto mr-0 flex-wrap w-10/12 justify-items-end md:w-1/2 mb-8 md:-mb-12 items-center pr-4">
            <Image
              objectFit="cover"
              alt={rightImage.alt}
              layout="intrinsic"
              quality="60"
              width="600"
              height="750"
              src={rightImage.url}
              className="object-cover h-full mr-0 ml-auto  min-w-img max-w-500px xl:max-w-570px rounded-br-xl"
              objectPosition={`${rightImage.focalPoint.x * 100}% ${rightImage.focalPoint.y * 100}%`}
            />
          </div>
          <div className="md:hidden absolute z-10 left-0 -ml-4 w-6/12 -mt-8">
            <Image
              alt={leftImage.alt}
              objectFit="cover"
              layout="responsive"
              quality="40"
              width="300"
              height="400"
              src={leftImage.url}
              className="rounded-tr-xl mr-4"
              objectPosition={`${leftImage.focalPoint.x * 100}% ${leftImage.focalPoint.y * 100}%`}
            />
          </div>
        </div>
        <div className="flex w-full md:w-1/2 items-center md:pl-8 lg:pl-12 xl:pl-12 pr-4 lg:pr-16">
          <div className="max-w-lg">
            <h2 className="font-extrabold text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl leading-headline">
              {introSection.headline}
            </h2>

            <hr className="w-12 border-black border-2 mt-8 md:mt-16 mb-4" />

            <div
              className="text-tiny md:text-lg leading-snug md:pb-10"
              dangerouslySetInnerHTML={{ __html: introSection.text }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Intro
